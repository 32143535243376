<script>
  import { createEventDispatcher } from 'svelte';
  import { fade, scale } from 'svelte/transition';
  import Scrim from './Scrim.svelte';

  const dispatch = createEventDispatcher();

  let className = '';

  export let alert = false;
  export let fullscreen = false;
  export let title = null;
  export { className as class };
</script>

<style>:root{--dialog-min-width:280px;--dialog-max-width:560px}[role=dialog]{z-index:50}[role=dialog].is-fullscreen .bg-surface{width:100%;height:100%}[role=dialog]:not(.is-fullscreen) .bg-surface{min-width:280px;min-width:var(--dialog-min-width);max-width:560px;max-width:var(--dialog-max-width)}[role=dialog] :global(.mdc-dialog__actions){display:-webkit-box;display:flex;-webkit-box-pack:end;justify-content:flex-end;padding:.5rem}[role=dialog] :global(.mdc-dialog__actions):empty{display:none}[role=dialog] :global(.mdc-dialog__actions) :global(.mdc-button){margin-left:.5rem}[role=dialog] :global(.mdc-dialog__actions) :global(.mdc-button):first-child{margin-left:0}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL21hdGVyaWFsLWNvbXBvbmVudHMvc3JjL2NvbXBvbmVudHMvRGlhbG9nLnN2ZWx0ZSIsIjxubyBzb3VyY2U+Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFLE1BQ0Usd0JBQXlCLENBQ3pCLHdCQUNGLENBRUEsY0NORixVRGlDRSxDQXhCRSx3Q0NUSixXQUFBLFdEV0ksQ0FFQSw4Q0FDRSxlQUFrQyxDQUFsQyxpQ0FBa0MsQ0FDbEMsZUFBa0MsQ0FBbEMsaUNBQ0YsQ0FFQSw0Q0NsQkosb0JBQUEsYUFBQSxxQkFBQSx5QkFBQSxhRGdDSSxDQVhFLGtEQ3JCTixZRHVCTSxDQUVBLGlFQ3pCTixpQkQrQk0sQ0FIRSw2RUM1QlIsYUQ4QlEiLCJmaWxlIjoiLi4vbWF0ZXJpYWwtY29tcG9uZW50cy9zcmMvY29tcG9uZW50cy9EaWFsb2cuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG4gIDpyb290IHtcbiAgICAtLWRpYWxvZy1taW4td2lkdGg6IDI4MHB4O1xuICAgIC0tZGlhbG9nLW1heC13aWR0aDogNTYwcHg7XG4gIH1cblxuICBbcm9sZT0nZGlhbG9nJ10ge1xuICAgIEBhcHBseSB6LTUwO1xuXG4gICAgJi5pcy1mdWxsc2NyZWVuIC5iZy1zdXJmYWNlIHtcbiAgICAgIEBhcHBseSB3LWZ1bGwgaC1mdWxsO1xuICAgIH1cblxuICAgICY6bm90KC5pcy1mdWxsc2NyZWVuKSAuYmctc3VyZmFjZSB7XG4gICAgICBtaW4td2lkdGg6IHZhcigtLWRpYWxvZy1taW4td2lkdGgpO1xuICAgICAgbWF4LXdpZHRoOiB2YXIoLS1kaWFsb2ctbWF4LXdpZHRoKTtcbiAgICB9XG5cbiAgICAmIDpnbG9iYWwoLm1kYy1kaWFsb2dfX2FjdGlvbnMpIHtcbiAgICAgIEBhcHBseSBmbGV4IGp1c3RpZnktZW5kIHAtMjtcblxuICAgICAgJjplbXB0eSB7XG4gICAgICAgIEBhcHBseSBoaWRkZW47XG4gICAgICB9XG5cbiAgICAgICYgOmdsb2JhbCgubWRjLWJ1dHRvbikge1xuICAgICAgICBAYXBwbHkgbWwtMjtcblxuICAgICAgICAmOmZpcnN0LWNoaWxkIHtcbiAgICAgICAgICBAYXBwbHkgbWwtMDtcbiAgICAgICAgfVxuICAgICAgfVxuICAgIH1cbiAgfVxuIixudWxsXX0= */</style>

<div class={className || null}>
  <aside
    role={alert ? 'alertdialog' : 'dialog'}
    aria-modal="true"
    class="mdc-dialog fixed inset-0 flex items-center justify-center"
    class:is-fullscreen={fullscreen}
  >
    <div
      class="mdc-dialog__surface overflow-hidden text-on-surface bg-surface
      shadow-24"
      class:rounded={!fullscreen}
      in:scale={{ start: 0.8, duration: 150 }}
      out:fade={{ duration: 75 }}
    >
      <div class="h-full" in:fade={{ duration: 75 }}>
        {#if !fullscreen && title}
          <h2 class="flex items-center h-16 px-6 text-xl font-medium">
            {title}
          </h2>
        {/if}

        <slot />

        <slot name="actions" />
      </div>
    </div>
    <Scrim on:click={() => dispatch('close')} />
  </aside>
</div>
