<script>
  import { toISO } from '@absently/date-fns-ext';
  import { mdiClose } from '@mdi/js';
  import { isAfter, isBefore, getHours, subMilliseconds } from 'date-fns';
  import { tick } from 'svelte';
  import Button from '../Button.svelte';
  import Calendar from './Calendar.svelte';
  import Dialog from '../Dialog.svelte';
  import Menu from '../Menu.svelte';
  import TextField from '../TextField.svelte';

  export let mobile = false;
  export let time = false;
  export let label = 'selected range';
  export let value = null;
  export let format = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'short',
  };

  // Human-readable text fields.
  const serializer = (date, { name }) =>
    subMilliseconds(new Date(date), name === 'end' ? 1 : 0).toLocaleDateString(
      'default',
      format
    );

  let closed = true;
  const input = { start: '', end: '' };
  let active;
  let calendar;
  let selection = value;
  let origin;

  $: is = mobile ? Dialog : Menu;
  $: props = { ...(is === Dialog ? { fullscreen: true } : {}) };
  // React to menu state, changed either by explicit assignment or via binding.
  $: if (closed) {
    // Deactivate input.
    active = null;
  } else {
    // Place focus within calendar.
    tick().then(() => calendar.focus());
  }
  $: isSelecting = !!origin;
  $: if (isSelecting) {
    const { bound } = origin;
    const date = origin[bound];

    // eslint-disable-next-line default-case
    switch (bound) {
      case 'start':
        active = isBefore(selection.start, date) ? 'start' : 'end';
        break;
      case 'end':
        active = isAfter(selection.end, date) ? 'end' : 'start';
        break;
    }
  }
  // Synchronize input values with selection.
  $: Object.entries(selection || {}).forEach(([bound, date]) => {
    input[bound] = toISO(date, { includeOffset: !time });
  });

  const open = () => {
    closed = false;
  };

  const handleSelect = ({ detail: { start, end } }) => {
    if (isSelecting) {
      // Bind value to selection.
      value = selection;
      origin = null;
      closed = true;
    } else {
      selection = { start, end };
      origin = { ...selection, bound: active };
    }
  };

  const update = ({ detail: { start, end } }) => {
    if (!isSelecting) return;

    if (isBefore(start, origin.end)) {
      selection = { start, end: origin.end };
    } else if (isAfter(end, origin.start)) {
      selection = { start: origin.start, end };
    }
  };
</script>

<style>:global(.mdc-date-range-picker [role=menu]){margin-top:-.75rem}fieldset span:first-child:after{margin-left:1.25rem;margin-right:1.25rem;margin-top:-.25rem;font-size:.875rem;content:"to"}fieldset :global(.mdc-text-field){width:14rem}header :global(.mdc-button):first-child{margin-left:.25rem}header :global(.mdc-button):last-child{height:100%;border-radius:0}header .label{font-size:1em}header .value{font-size:2.4em}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL21hdGVyaWFsLWNvbXBvbmVudHMvc3JjL2NvbXBvbmVudHMvcGlja2VyL0RhdGVSYW5nZVBpY2tlci5zdmVsdGUiLCI8bm8gc291cmNlPiJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRSw0Q0NERixrQkRJRSxDQUlFLGdDQ1JKLG9CQUFBLHFCQUFBLG1CQUFBLGtCQUFBLEFEWU0sWUFDRixDQUVBLGtDQ2ZKLFdEaUJJLENBS0Usd0NDdEJOLGtCRHdCTSxDQUVBLHVDQzFCTixZQUFBLGVENEJNLENBR0YsY0FDRSxhQUNGLENBRUEsY0FDRSxlQUNGIiwiZmlsZSI6Ii4uL21hdGVyaWFsLWNvbXBvbmVudHMvc3JjL2NvbXBvbmVudHMvcGlja2VyL0RhdGVSYW5nZVBpY2tlci5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbiAgOmdsb2JhbCgubWRjLWRhdGUtcmFuZ2UtcGlja2VyIFtyb2xlPSdtZW51J10pIHtcbiAgICAvKiBIaWRlIGhhbGYtZGF5IGhlbHBlciB0ZXh0LiAqL1xuICAgIEBhcHBseSAtbXQtMztcbiAgfVxuXG4gIC8qIHN0eWxlbGludC1kaXNhYmxlIG5vLWRlc2NlbmRpbmctc3BlY2lmaWNpdHkgKi9cbiAgZmllbGRzZXQge1xuICAgICYgc3BhbjpmaXJzdC1jaGlsZDo6YWZ0ZXIge1xuICAgICAgQGFwcGx5IG14LTUgLW10LTEgdGV4dC1zbTtcblxuICAgICAgLyogW1RPRE99IGkxOG4uICovXG4gICAgICBjb250ZW50OiAndG8nO1xuICAgIH1cblxuICAgICYgOmdsb2JhbCgubWRjLXRleHQtZmllbGQpIHtcbiAgICAgIEBhcHBseSB3LTU2O1xuICAgIH1cbiAgfVxuXG4gIGhlYWRlciB7XG4gICAgJiA6Z2xvYmFsKC5tZGMtYnV0dG9uKSB7XG4gICAgICAmOmZpcnN0LWNoaWxkIHtcbiAgICAgICAgQGFwcGx5IG1sLTE7XG4gICAgICB9XG5cbiAgICAgICY6bGFzdC1jaGlsZCB7XG4gICAgICAgIEBhcHBseSBoLWZ1bGwgcm91bmRlZC1ub25lO1xuICAgICAgfVxuICAgIH1cblxuICAgICYgLmxhYmVsIHtcbiAgICAgIGZvbnQtc2l6ZTogMWVtO1xuICAgIH1cblxuICAgICYgLnZhbHVlIHtcbiAgICAgIGZvbnQtc2l6ZTogMi40ZW07XG4gICAgfVxuICB9XG4gIC8qIHN0eWxlbGludC1lbmFibGUgKi9cbiIsbnVsbF19 */</style>

<svelte:component
  this={is}
  bind:closed
  class="mdc-date-range-picker"
  {...props}
>
  <fieldset slot="activator" aria-expanded={!closed} on:focusin={open}>
    <div class="flex">
      {#each ['start', 'end'] as bound}
        <span
          class="flex items-center"
          on:focusin={() => {
            active = bound;
          }}
        >
          <TextField
            active={active === bound}
            name={bound}
            value={input[bound]}
            label={bound.charAt(0).toUpperCase() + bound.slice(1)}
            hint={selection && getHours(selection[bound]) !== 0 ? 'half-day' : ' '}
            readonly
            {serializer}
          />
        </span>
      {/each}
    </div>
  </fieldset>
  <div class="flex flex-col h-full" class:text-fluid={mobile}>
    {#if is === Dialog}
      <header
        class="flex flex-col justify-between h-32 pb-6 text-on-primary
        bg-primary"
      >
        <div class="flex items-center justify-between h-14">
          <Button variant="unelevated" icon={mdiClose} />
          <Button variant="unelevated">save</Button>
        </div>
        <div class="pl-16 pr-4">
          {#if label}
            <h2
              class="label relative font-medium leading-none uppercase
              tracking-widest"
            >
              {label}
            </h2>
          {/if}
          <span class="value flex items-end h-9 leading-none">
            <!-- TODO -->
            Nov 17 – Nov 26
          </span>
        </div>
      </header>
    {/if}

    <Calendar
      bind:this={calendar}
      {mobile}
      density={mobile ? 'comfortable' : 'compact'}
      step={0.5}
      monthsCount={is === Menu ? 2 : Infinity}
      {selection}
      on:cell:click={handleSelect}
      on:cell:focus={update}
      on:cell:mouseenter={update}
    />
  </div>
</svelte:component>
