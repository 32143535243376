<script context="module">
  const gap = 3;
  const rowcount = 8; // 7 days in a week + header
  const styles = {
    row: 10,
    col: 10,
    gap,
    margin: { t: gap, r: 0, b: 0, l: 0 },
  };
</script>

<script>
  import {
    contains,
    eachDayOfInterval,
    eachMonthOfInterval,
  } from '@absently/date-fns-ext';
  import {
    addWeeks,
    differenceInCalendarWeeks,
    getDay,
    startOfWeek,
  } from 'date-fns';
  import split from 'just-split';
  import { getContext } from 'svelte';
  import { rotate } from '@/utils/array';
  import { colorbrewer, scale } from '@/utils/color';
  import { getAbbrDayName, localize } from '@/utils/i18n';

  export let date;
  export let weeksCount = 52;
  export let items = {};

  const { weekStartsOn } = getContext('l10n');

  $: colcount = weeksCount + 1; // inc header
  $: width =
    styles.margin.l +
    colcount * styles.col +
    (colcount - 1) * styles.gap +
    styles.margin.r;
  $: height =
    styles.margin.t +
    rowcount * styles.row +
    (rowcount - 1) * styles.gap +
    styles.margin.b;
  $: start = startOfWeek(date); // use default Sun-Sat week
  $: i = { start, end: addWeeks(start, weeksCount) };
  $: weeks = split(eachDayOfInterval(i), 7);
  $: months = eachMonthOfInterval(i);
  $: xTicks = months.reduce(
    (acc, m) => [...acc, ...(contains(i, m) ? [m] : [])],
    []
  );
  $: yTicks = rotate(Array.from(Array(7), (_, n) => n), -weekStartsOn);

  const getBoundingClientRect = day => {
    const rowindex = getDay(day);
    const colindex = differenceInCalendarWeeks(day, start);

    const top = styles.margin.t + rowindex * (styles.row + styles.gap);
    const left = styles.margin.l + (colindex + 1) * (styles.col + styles.gap);

    return { top, right: left + styles.col, bottom: top + styles.row, left };
  };
</script>

<style>svg{overflow:visible!important}[role=columnheader],[role=rowheader]{font-weight:500;text-transform:uppercase;opacity:.5;font-size:8px}[role=gridcell]{color:#eee}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL0hlYXRtYXAuc3ZlbHRlIiwiPG5vIHNvdXJjZT4iXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0UsSUFDRSwwQkFDRixDQUVBLHFDQ0xGLGdCQUFBLHlCQUFBLFdBQUEsQURTSSxhQUNGLENBRUEsZ0JBQ0UsVUFDRiIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9IZWF0bWFwLnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuICBzdmcge1xuICAgIG92ZXJmbG93OiB2aXNpYmxlICFpbXBvcnRhbnQ7XG4gIH1cblxuICBbcm9sZT0nY29sdW1uaGVhZGVyJ10sXG4gIFtyb2xlPSdyb3doZWFkZXInXSB7XG4gICAgQGFwcGx5IGZvbnQtbWVkaXVtIHVwcGVyY2FzZSBvcGFjaXR5LTUwO1xuXG4gICAgZm9udC1zaXplOiA4cHg7XG4gIH1cblxuICBbcm9sZT0nZ3JpZGNlbGwnXSB7XG4gICAgY29sb3I6IHRoZW1lKCdjb2xvcnMuZ3JleS4yMDAnKTtcbiAgfVxuIixudWxsXX0= */</style>

<svg {width} {height}>
  <g role="grid">
    {#each xTicks as m, id}
      <text
        role="columnheader"
        x={getBoundingClientRect(m).left}
        y={height - styles.margin.b - styles.row / 2}
        dy="0.6ex"
      >
        {localize(m, { month: 'short' })}
      </text>
    {/each}

    {#each yTicks as day, idx}
      <text
        role="rowheader"
        y={styles.margin.t + styles.row / 2 + idx * (styles.row + styles.gap)}
        dy="0.6ex"
      >
        {getAbbrDayName(day).slice(0, 1)}
      </text>
    {/each}

    <g
      transform={`translate(${styles.margin.l + styles.row + styles.gap}, ${styles.margin.t})`}
    >
      {#each weeks as week, wIdx (`w-${+week[0]}`)}
        <g
          role="rowgroup"
          transform={`translate(${wIdx * (styles.col + styles.gap)})`}
        >
          {#each week as day, dIdx (`d-${+day}`)}
            <rect
              id={+day}
              data-scale={scale(items[+day])}
              role="gridcell"
              y={dIdx * (styles.row + styles.gap)}
              rx={styles.col / 2}
              ry={styles.row / 2}
              width={styles.col}
              height={styles.row}
              fill={colorbrewer.Reds[scale(items[+day])] || 'currentColor'}
            />
          {/each}
        </g>
      {/each}
    </g>
  </g>
</svg>
