<script>
  import { Icon } from '@absently/mdc';
  import i18n from '@/locales';
  import page from '@/stores/page';

  export let route;
  export let icon;

  $: href = `/${$page.params.account}/${route}`;
  $: active = $page.path.startsWith(href);
</script>

<style>a{display:-webkit-box;display:flex;-webkit-box-align:center;align-items:center;height:2.5rem;margin:.5rem;padding-left:.5rem;padding-right:.5rem;font-size:.875rem;font-weight:500;border-radius:.25rem;cursor:pointer}a.active{color:#3f50b5;background-color:rgba(63,80,181,.12)}a:not(.active):hover{background-color:rgba(0,0,0,.04)}a :global(.icon){margin-right:2rem}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL05hdmlnYXRpb25EcmF3ZXIvSXRlbS5zdmVsdGUiLCI8bm8gc291cmNlPiJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRSxFQ0RGLG9CQUFBLGFBQUEseUJBQUEsbUJBQUEsY0FBQSxhQUFBLG1CQUFBLG9CQUFBLGtCQUFBLGdCQUFBLHFCQUFBLGNEaUJFLENBYkUsU0NKSixjQUFBLEFET00sb0NBQ0YsQ0FFQSxxQkFDRSxnQ0FDRixDQUVBLGlCQ2RKLGlCRGdCSSIsImZpbGUiOiJzcmMvY29tcG9uZW50cy9OYXZpZ2F0aW9uRHJhd2VyL0l0ZW0uc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG4gIGEge1xuICAgIEBhcHBseSBmbGV4IGl0ZW1zLWNlbnRlciBoLTEwIG0tMiBweC0yIHRleHQtc20gZm9udC1tZWRpdW0gcm91bmRlZCBjdXJzb3ItcG9pbnRlcjtcblxuICAgICYuYWN0aXZlIHtcbiAgICAgIEBhcHBseSAudGV4dC1wcmltYXJ5O1xuXG4gICAgICBiYWNrZ3JvdW5kLWNvbG9yOiBjb2xvci1tb2QodGhlbWUoJ2NvbG9ycy5wcmltYXJ5JykgYSgxMiUpKTtcbiAgICB9XG5cbiAgICAmOm5vdCguYWN0aXZlKTpob3ZlciB7XG4gICAgICBiYWNrZ3JvdW5kLWNvbG9yOiBjb2xvci1tb2QodGhlbWUoJ2NvbG9ycy5vbi1zdXJmYWNlJykgYSg0JSkpO1xuICAgIH1cblxuICAgICYgOmdsb2JhbCguaWNvbikge1xuICAgICAgQGFwcGx5IG1yLTg7XG4gICAgfVxuICB9XG4iLG51bGxdfQ== */</style>

<a {href} class:active on:click|preventDefault>
  <Icon path={icon} />
  {i18n.routes[route]}
</a>
