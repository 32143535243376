<script context="module">
  import navaid from 'navaid';

  const router = navaid();

  export const goto = router.route;
</script>

<script>
  import { onDestroy, setContext } from 'svelte';
  import { encode, decode } from 'qss';
  import Nav from '@/components/Nav';
  import NavigationDrawer from '@/components/NavigationDrawer';
  import page from '@/stores/page';
  // eslint-disable-next-line import/no-cycle
  import { auth, user } from '@/stores/session';
  // eslint-disable-next-line import/no-cycle
  import * as api from '@/utils/api';
  import events from '@/utils/bus';

  // Init event bus.
  setContext('bus', events);
  setContext('l10n', { weekStartsOn: 1 });

  let Route; // current page component
  let props;
  let { innerHeight } = window;

  // Wait for the session to verify before binding the router.
  $: if (!$auth || $user) router.listen();

  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile
  $: vh = innerHeight * 0.01;

  const draw = (m, obj = {}) => {
    // eslint-disable-next-line no-shadow
    const { auth = true, params } = obj;
    const { href, pathname: path, search } = location;
    const query = decode(search.substring(1));

    page.update(state => ({ ...state, href, path, params, query }));

    if (auth /* required */ && !$auth /* provided */) {
      // Require user to authenticate, returning to whence they came upon grant.
      goto(encode({ from: [path, search].join('') }, '/?'));
    } else if (m.preload) {
      m.preload($page, { user: $user }).then(_props => {
        // `preload` may have redirected.
        if (location.href === $page.href) {
          props = _props;
          Route = m.default;
        }
      });
    } else {
      props = {}; // reset
      Route = m.default;
    }
  };

  const logOut = () => {
    api.del('session'); // fire and forget
    $auth = false;
    // Clear in-memory caches and disconnect socket.
    location.assign('/');
  };

  router
    .on('/', () => import('@/routes/Home').then(m => draw(m, { auth: false })))
    .on('/:account/planner', obj =>
      import('@/routes/Planner').then(m => draw(m, { params: obj }))
    )
    .on('/:account', obj =>
      goto(`/${obj.account}/planner`, /* replace */ true)
    );
  // [TODO] 404 handler.

  onDestroy(router.unlisten);
</script>

<style>:global(.mdc-top-app-bar)~:global(aside:not([aria-modal])),:global(.mdc-top-app-bar)~:global(main){padding-top:3.5rem}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9BcHAuc3ZlbHRlIiwiPG5vIHNvdXJjZT4iXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUksbUdDRkosa0JES0kiLCJmaWxlIjoic3JjL0FwcC5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbiAgOmdsb2JhbCgubWRjLXRvcC1hcHAtYmFyKSB7XG4gICAgJiB+IDpnbG9iYWwobWFpbiksXG4gICAgJiB+IDpnbG9iYWwoYXNpZGU6bm90KFthcmlhLW1vZGFsXSkpIHtcbiAgICAgIEBhcHBseSBwdC0xNDtcbiAgICB9XG4gIH1cbiIsbnVsbF19 */</style>

<svelte:window bind:innerHeight />

<svelte:head>
  <title>Absently</title>
</svelte:head>

<div id="app" style="--vh: {vh}px;">
  {#if $auth}
    <NavigationDrawer on:session:revoke={logOut} />
  {/if}

  <div class="flex">
    {#if $auth}
      <Nav on:session:revoke={logOut} />
    {/if}

    <svelte:component this={Route} {...props} />
  </div>
</div>
