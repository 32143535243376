<script>
  import { isEqual } from 'date-fns';
  import omit from 'just-omit';
  import { getContext } from 'svelte';
  import { CALENDAR } from './Calendar.svelte';

  let className = '';

  export let interval;
  export let role = 'button'; // or `'group'` or `'presentation'`
  export let mask = '';
  export { className as class };

  const state = getContext(CALENDAR);

  let el;
  let tabindex;
  let selected;

  $: if (role === 'button') {
    const current =
      isEqual(interval.start, $state.focus.start) ||
      isEqual(interval.end, $state.focus.end);

    tabindex = current ? 0 : -1; // roving tab index
    selected = /^\[*\]*$/.test(mask);
  }
</script>

<style>[role=button]{outline-offset:-3px}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL21hdGVyaWFsLWNvbXBvbmVudHMvc3JjL2NvbXBvbmVudHMvcGlja2VyL0J1dHRvbi5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0UsY0FFRSxtQkFDRiIsImZpbGUiOiIuLi9tYXRlcmlhbC1jb21wb25lbnRzL3NyYy9jb21wb25lbnRzL3BpY2tlci9CdXR0b24uc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG4gIFtyb2xlPSdidXR0b24nXSB7XG4gICAgLyogUGxhY2UgZm9jdXMgcmluZyBpbnNpZGU7IFtGSVhNRV0gY3JvcHBlZCB3aGVuIG91dGxpbmVkLiAqL1xuICAgIG91dGxpbmUtb2Zmc2V0OiAtM3B4O1xuICB9XG4iXX0= */</style>

<div
  bind:this={el}
  {role}
  {tabindex}
  class={className}
  aria-selected={selected || null}
  {...omit($$props, ['interval', 'role', 'mask', 'class'])}
  on:focus
  on:mouseenter
>
  <slot />
</div>
