<script context="module">
  /* eslint-disable import/order */
  import { toISODate } from '@absently/date-fns-ext';
  import equal from 'fast-deep-equal';
  import { encode } from 'qss';
  import { goto } from '@/App';
  import store from '@/stores/services';

  export const preload = async (page, sess) => {
    const { user } = sess;
    // [TODO] the following is arbitrary; instead, record a user's default team,
    // and track session's current value via `localStorage`.
    const [[scope]] = user.groups;
    const query = {
      // Defaults...
      scope,
      d: toISODate(Date.now()),

      ...page.query,
    };

    // Validate.
    if (!user.groups.some(([team]) => team === query.scope))
      query.scope = scope; // reset to user's default team

    // Ensure the resulting page has an explicit address.
    if (!equal(page.query, query))
      return goto(encode(query, '?'), /* replace */ true);

    const { scope: team, d } = page.query;
    // [TODO] validate/redirect invalid `d` query params.
    const date = new Date(d);

    // Ensure the store is/becomes populated with the team.
    if (!store.teams.peek(team)) store.teams.get(team);

    return { team, date };
  };
</script>

<script>
  import { Button, Calendar } from '@absently/mdc';
  import { mdiPlus } from '@mdi/js';
  import { get } from 'svelte/store';
  import Agenda from '@/components/Agenda';
  import Wallchart from '@/components/Wallchart';
  import { mobile } from '@/stores/media';
  import page from '@/stores/page';
  import { filter } from '@/stores/planner';
  import { user } from '@/stores/session';

  export let team;
  export let date;

  let calendar;
  let editing = false;

  $: $filter.team = team;
  $: search = { scope: team, d: toISODate(date) };
  $: if (!equal(search, get(page).query)) {
    // Keep location [search params] in sync.
    goto(encode(search, '?'), /* replace */ true);
  }
  // Sync calendar navigator with external changes to `date`. (When nav drawer
  // is dismissed, calendar component is cleared-down but its binding is
  // retained...Svelte bug?).
  $: if (calendar && calendar.goto) calendar.goto(date);

  const handleNewAbsence = ev => {
    editing = ev.detail || { absentee: $user.emp };
  };
</script>

<style>:global(.mdc-top-app-bar__title){display:none}@media (min-width:1024px){:global(.mdc-top-app-bar__title){display:block}}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9yb3V0ZXMvUGxhbm5lci5zdmVsdGUiLCI8bm8gc291cmNlPiJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRSxpQ0NERixZRE9FLENBSEUsMEJBSEYsaUNDREYsYURPRSxDQURFIiwiZmlsZSI6InNyYy9yb3V0ZXMvUGxhbm5lci5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbiAgOmdsb2JhbCgubWRjLXRvcC1hcHAtYmFyX190aXRsZSkge1xuICAgIEBhcHBseSBoaWRkZW47XG5cbiAgICBAc2NyZWVuIGxnIHtcbiAgICAgIEBhcHBseSBibG9jaztcbiAgICB9XG4gIH1cbiIsbnVsbF19 */</style>

<svelte:component this={$mobile ? Agenda : Wallchart} {team} bind:date>
  <div slot="calnav" let:concealed={collapsed}>
    <!-- events={$diary} -->
    <Calendar
      bind:this={calendar}
      mobile={$mobile}
      density={$mobile ? 'comfortable' : 'compact'}
      {collapsed}
      selection={date}
      on:cell:click={ev => {
        date = ev.detail.start;
      }}
    />
  </div>
</svelte:component>

<div class="fixed right-0 bottom-0 m-4">
  <Button fab color="secondary" icon={mdiPlus} on:click={handleNewAbsence} />
</div>
