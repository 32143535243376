<script>
  import { createEventDispatcher } from 'svelte';
  import { quartOut } from 'svelte/easing';
  import { fade } from 'svelte/transition';
  import { mobile } from '@/stores/media';
  import { fly } from '@/transitions';

  const dispatch = createEventDispatcher();

  export let modal = false;
  export let right = false;

  let w;

  $: isModal = modal || $mobile;
</script>

<style>aside{min-width:256px}aside[aria-modal]{--mx:56px;position:fixed;top:0;bottom:0;left:0;z-index:50;box-shadow:0 8px 10px -5px rgba(0,0,0,.2),0 16px 24px 2px rgba(0,0,0,.14),0 6px 30px 5px rgba(0,0,0,.12);right:var(--mx)}@media (min-width:480px){aside[aria-modal]{right:unset}}aside[aria-modal][data-side=right]{right:0;left:var(--mx)}@media (min-width:480px){aside[aria-modal][data-side=right]{left:unset}}aside:not([aria-modal])[data-side=left]{float:left;box-shadow:1px 0 0 0 var(--divider)}aside:not([aria-modal])[data-side=right]{float:right;box-shadow:-1px 0 0 0 var(--divider)}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL1NpZGVTaGVldC5zdmVsdGUiLCI8bm8gc291cmNlPiJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRSxNQUNFLGVBcUNGLENBbkNFLGtCQUNFLFNBQVUsQ0NMaEIsZUFBQSxNQUFBLFNBQUEsT0FBQSxXQUFBLHlHQUFBLEFEU00sZUFlRixDQWJFLHlCQVBGLGtCQVFJLFdBWUosQ0FYRSxDQUVBLG1DQ2ZOLFFBQUEsQURrQlEsY0FLRixDQUhFLHlCQUxGLG1DQU1JLFVBRUosQ0FERSxDQUtGLHdDQzNCTixXQUFBLEFEOEJRLG1DQUNGLENBRUEseUNDakNOLFlBQUEsQURvQ1Esb0NBQ0YiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvU2lkZVNoZWV0LnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuICBhc2lkZSB7XG4gICAgbWluLXdpZHRoOiAyNTZweDtcblxuICAgICZbYXJpYS1tb2RhbF0ge1xuICAgICAgLS1teDogNTZweDtcblxuICAgICAgQGFwcGx5IGZpeGVkIGluc2V0LXktMCBsZWZ0LTAgei01MCBzaGFkb3ctMTY7XG5cbiAgICAgIHJpZ2h0OiB2YXIoLS1teCk7XG5cbiAgICAgIEBzY3JlZW4geHMge1xuICAgICAgICByaWdodDogdW5zZXQ7XG4gICAgICB9XG5cbiAgICAgICZbZGF0YS1zaWRlPSdyaWdodCddIHtcbiAgICAgICAgQGFwcGx5IHJpZ2h0LTA7XG5cbiAgICAgICAgbGVmdDogdmFyKC0tbXgpO1xuXG4gICAgICAgIEBzY3JlZW4geHMge1xuICAgICAgICAgIGxlZnQ6IHVuc2V0O1xuICAgICAgICB9XG4gICAgICB9XG4gICAgfVxuXG4gICAgJjpub3QoW2FyaWEtbW9kYWxdKSB7XG4gICAgICAmW2RhdGEtc2lkZT0nbGVmdCddIHtcbiAgICAgICAgQGFwcGx5IGZsb2F0LWxlZnQ7XG5cbiAgICAgICAgYm94LXNoYWRvdzogMXB4IDAgMCAwIHZhcigtLWRpdmlkZXIpO1xuICAgICAgfVxuXG4gICAgICAmW2RhdGEtc2lkZT0ncmlnaHQnXSB7XG4gICAgICAgIEBhcHBseSBmbG9hdC1yaWdodDtcblxuICAgICAgICBib3gtc2hhZG93OiAtMXB4IDAgMCAwIHZhcigtLWRpdmlkZXIpO1xuICAgICAgfVxuICAgIH1cbiAgfVxuIixudWxsXX0= */</style>

{#if isModal}
  <div
    class="scrim z-40"
    transition:fade={{ duration: 250 }}
    on:click={() => dispatch('close')}
  />
{/if}

<aside
  aria-modal={isModal || undefined}
  class="inset-y-0 h-screen overflow-y-scroll"
  class:sticky={!isModal}
  data-side={right ? 'right' : 'left'}
  transition:fly|local={{ duration: 250, easing: quartOut, x: w * (right ? 1 : -1), opacity: 1, reflow: !isModal }}
>
  <div class="h-full bg-surface" bind:clientWidth={w}>
    <slot />
  </div>
</aside>
