<script>
  import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
  import Icon from './Icon.svelte';

  export let concealed = true;
  export let breakpoints = [0];

  let idx = 0;

  $: breakpoint = breakpoints[idx];

  const toggle = () => {
    idx = (idx + 1) % breakpoints.length;
    concealed = !idx;
  };
</script>

<style>.mdc-backdrop{padding-top:var(--mdc-top-app-bar-height)}.spacer{-webkit-transition-timing-function:cubic-bezier(.4,0,1,1);transition-timing-function:cubic-bezier(.4,0,1,1);-webkit-transition-duration:175ms;transition-duration:175ms}.mdc-backdrop--revealed .spacer{-webkit-transition-timing-function:cubic-bezier(0,0,.2,1);transition-timing-function:cubic-bezier(0,0,.2,1);-webkit-transition-duration:.25s;transition-duration:.25s}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL21hdGVyaWFsLWNvbXBvbmVudHMvc3JjL2NvbXBvbmVudHMvQmFja2Ryb3Auc3ZlbHRlIiwiPG5vIHNvdXJjZT4iXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0UsY0FDRSx5Q0FDRixDQUVBLFFDTEYsMERBQUEsa0RBQUEsQURRSSxpQ0FBMEIsQ0FBMUIseUJBT0YsQ0FWQSxnQ0NMRiwwREFBQSxrREFBQSxBRGFNLGdDQUEwQixDQUExQix3QkFFSiIsImZpbGUiOiIuLi9tYXRlcmlhbC1jb21wb25lbnRzL3NyYy9jb21wb25lbnRzL0JhY2tkcm9wLnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuICAubWRjLWJhY2tkcm9wIHtcbiAgICBwYWRkaW5nLXRvcDogdmFyKC0tbWRjLXRvcC1hcHAtYmFyLWhlaWdodCk7XG4gIH1cblxuICAuc3BhY2VyIHtcbiAgICBAYXBwbHkgdHJhbnNpdGlvbi1hY2NlbGVyYXRpb247XG5cbiAgICB0cmFuc2l0aW9uLWR1cmF0aW9uOiAxNzVtcztcblxuICAgIEBuZXN0IC5tZGMtYmFja2Ryb3AtLXJldmVhbGVkICYge1xuICAgICAgQGFwcGx5IHRyYW5zaXRpb24tZGVjZWxlcmF0aW9uO1xuXG4gICAgICB0cmFuc2l0aW9uLWR1cmF0aW9uOiAyNTBtcztcbiAgICB9XG4gIH1cbiIsbnVsbF19 */</style>

<div
  class="mdc-backdrop mdc-backdrop--{concealed ? 'concealed' : 'revealed'} fixed
  inset-0 bg-primary"
>
  <div class="mdc-backdrop__back absolute inset-x-0">
    <slot />
  </div>

  <aside class="flex flex-col h-full">
    <div
      class="spacer flex-shrink-0 pointer-events-none"
      style="height: {breakpoint}px;"
    />

    <div
      class="mdc-backdrop__front z-10 flex flex-col flex-grow overflow-y-hidden
      bg-surface rounded-t-xl"
    >
      <header class="pt-3 px-4 cursor-pointer" on:click={toggle}>
        <div
          class="flex items-center justify-between pb-3 border-b border-grey-300"
        >
          <h1>
            <slot name="subheader" />
          </h1>
          <slot name="control">
            <Icon path={concealed ? mdiChevronDown : mdiChevronUp} />
          </slot>
        </div>
      </header>
      <section class="pt-3 px-4 overflow-y-scroll">
        <slot name="content" />
      </section>
    </div>
  </aside>
</div>
