<script>
  import { createEventDispatcher } from 'svelte';
  import Button from './Button.svelte';
  import NavigationIcon from './NavigationIcon.svelte';

  const dispatch = createEventDispatcher();

  export let navigationIcon = 'menu';
  export let title = '';
</script>

<style>:root{--mdc-top-app-bar-height:3.5rem}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL21hdGVyaWFsLWNvbXBvbmVudHMvc3JjL2NvbXBvbmVudHMvVG9wQXBwQmFyLnN2ZWx0ZSJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRSxNQUNFLCtCQUNGIiwiZmlsZSI6Ii4uL21hdGVyaWFsLWNvbXBvbmVudHMvc3JjL2NvbXBvbmVudHMvVG9wQXBwQmFyLnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuICA6cm9vdCB7XG4gICAgLS1tZGMtdG9wLWFwcC1iYXItaGVpZ2h0OiB0aGVtZSgnc3BhY2luZy4xNCcpO1xuICB9XG4iXX0= */</style>

<header
  class="mdc-top-app-bar fixed top-0 inset-x-0 z-30 flex items-center h-14 px-1
  xs:px-3 text-on-primary bg-primary"
>
  <Button class="mr-5" icon={true} on:click={() => dispatch('navicon:click')}>
    <NavigationIcon variant={navigationIcon} />
  </Button>

  <h1 id="page-title" class="mdc-top-app-bar__title text-xl font-medium">
    {title}
  </h1>

  <div class="flex flex-grow items-center">
    <slot />
  </div>

  <slot name="page-action-items" />
</header>
