<script context="module">
  // Heavily inspired by https://developers.google.com/web/updates/2016/07/infinite-scroller.

  // Number of items to instantiate beyond the current view in either scroll
  // direction.
  const AHEAD = 2;
  const BEHIND = 2;

  // The number of pixels of additional length to allow scrolling to.
  const RUNOFF = 2000;
</script>

<script>
  /* eslint-disable no-use-before-define */
  import { onMount, tick } from 'svelte';

  // Can be a pixel value or a function which returns the value given an item
  // supplied.
  export let itemHeight;
  // Used to approximate number of items needed to fill the scroller's container.
  export let minItemHeight = itemHeight;
  // The initial item to list at the top of the scroller.
  export let initial;
  // Functions that, when supplied with a given item, return identifiers for the
  // previous and next items.
  export let prev;
  export let next;

  const getItemHeight = item =>
    typeof itemHeight === 'function' ? itemHeight(item) : itemHeight;

  const ahead = AHEAD;
  let behind = 0; // to be set
  let runoff = 0; // on mount

  let el;
  let clientHeight = 0;
  // "Runway" length.
  let scrollHeight = 0;
  let prevScrollTop = 0;

  // Represents the item intersecting with the top of this scroller and its
  // offset thereof.
  // The list is initially anchored to the top (offset === 0) of the first item.
  let anchor = { item: initial, offset: 0 };

  // Store of pixel heights of items visited...
  const cache = {};
  // Items attached [to the DOM].
  let stack;
  let head = 0;
  let tail = head;

  // eslint-disable-next-line no-sequences, no-unused-expressions
  $: clientHeight, init();

  onMount(async () => {
    behind = BEHIND;
    runoff = RUNOFF;

    el.addEventListener('scroll', handleScroll);

    // Wait for the DOM to update...
    await tick();
    // ...then set the scroller's scroll position so that the initial item
    // remains at the top.
    el.scrollTop = stack[BEHIND].top;
    prevScrollTop = el.scrollTop;

    return () => el.removeEventListener('scroll', handleScroll);
  });

  let attach;
  (attach = item => {
    if (!(item in cache)) {
      const height = getItemHeight(item);

      cache[item] = height;
      scrollHeight += height;
    }

    return cache[item];
  })(initial); // attach the initial item

  const unshift = (recycle = false) => {
    const nextSibling = stack[head];
    const item = prev(nextSibling.item);
    const { top: bottom } = nextSibling;

    attach(item);

    const first = { item, top: bottom - cache[item], bottom };

    if (recycle) {
      head = tail;
      tail = tail === 0 ? stack.length - 1 : tail - 1;
      stack[head] = first;
    } else {
      stack = [first, ...stack];
      tail += 1;
    }
  };

  const push = (recycle = false) => {
    const previousSibling = stack[tail];
    const item = next(previousSibling.item);
    const { bottom: top } = previousSibling;

    attach(item);

    const last = { item, top, bottom: top + cache[item] };

    if (recycle) {
      tail = head;
      head = head === stack.length - 1 ? 0 : head + 1;
      stack[tail] = last;
    } else {
      stack = [...stack, last];
      tail += 1;
    }
  };

  const init = () => {
    // Reset...
    const top = runoff + anchor.offset;
    // eslint-disable-next-line no-multi-assign
    head = tail = 0;
    stack = [{ item: anchor.item, top, bottom: top + cache[anchor.item] }];

    // Construct stack of attached items.
    [
      ...Array(behind + (Math.ceil(clientHeight / minItemHeight) + 1) + ahead),
    ].forEach((_, idx) => {
      switch (Math.sign(idx - behind)) {
        case -1:
          unshift();
          break;
        case 1:
          push();
          break;
        default:
          break;
      }
    });

    // Add the runoff to the runway length.
    scrollHeight = stack[tail - AHEAD].bottom + RUNOFF;
  };

  const scrollTo = (scrollTop = 0) => {
    // Interrupt momentum-based scrolling.
    el.style['-webkit-overflow-scrolling'] = 'auto';
    el.scrollTop = scrollTop;
    el.style['-webkit-overflow-scrolling'] = 'touch';
  };

  /* eslint-disable no-param-reassign */
  const update = delta => {
    if (!delta) return;

    let { item } = anchor;
    delta += anchor.offset;
    if (delta < 0) {
      let gain = 0;

      // We've scrolled up and intersected with a different item, but which?
      while (delta < 0) {
        unshift(/* recycle */ true);
        item = prev(item);
        delta += attach(item);
        gain += cache[stack[head].item];
      }

      // [FIXME] janky on iOS due to interruption in momentum scrolling --
      // consider having a huge upper runoff and only resetting the scroll when
      // there is zero inertia.
      if (!(prev(stack[head].item) in cache)) {
        // We've scrolled above the first item visited; reposition the stack to
        // reset the runoff.
        stack.forEach((_, idx) => {
          stack[idx].top += gain;
          stack[idx].bottom += gain;
        });
        scrollTo(el.scrollTop + gain);
      }
    } else {
      // We could be scrolling in either direction; regardless, update offset
      // and -- if we've scrolled the anchor out of view -- sync with the new
      // item.
      while (delta > 0 && cache[item] < delta) {
        push(/* recycle */ true);
        delta -= cache[item];
        item = next(item);
      }
    }

    anchor = { item, offset: delta };
  };

  const handleScroll = () => {
    const delta = el.scrollTop - prevScrollTop;

    update(delta);
    prevScrollTop = el.scrollTop;
  };
  /* eslint-enable */
</script>

<style>ul{position:absolute;width:100%;height:100%;overflow-x:hidden;overflow-y:scroll;-webkit-overflow-scrolling:touch}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL21hdGVyaWFsLWNvbXBvbmVudHMvc3JjL2NvbXBvbmVudHMvSW5maW5pdGVTY3JvbGxlci5zdmVsdGUiLCI8bm8gc291cmNlPiJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRSxHQ0RGLGtCQUFBLFdBQUEsWUFBQSxrQkFBQSxrQkFBQSxBRFFJLGdDQUNGIiwiZmlsZSI6Ii4uL21hdGVyaWFsLWNvbXBvbmVudHMvc3JjL2NvbXBvbmVudHMvSW5maW5pdGVTY3JvbGxlci5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbiAgdWwge1xuICAgIC8qIFRoZSBmb2xsb3dpbmcgcnVsZXMgbXVzdCBiZSBzZXQgYXMgLS0gd2hhdCBhcmUgaW4gZXNzZW5jZSAtLSBpbmxpbmUgW3RvXG4gICAgICAgdGhlIGNvbXBvbmVudF0gc3R5bGVzIGJlY2F1c2UgdGhlIG1haW4gc3R5bGVzaGVldCBtYXkgbm90IHlldCBoYXZlIGxvYWRlZCxcbiAgICAgICBhbmQgc2Nyb2xsaW5nIGlzIGRlcGVuZGVudCBvbiB0aGVzZSBzZXR0aW5ncy4gKi9cbiAgICBAYXBwbHkgYWJzb2x1dGUgdy1mdWxsIGgtZnVsbCBvdmVyZmxvdy14LWhpZGRlbiBvdmVyZmxvdy15LXNjcm9sbDtcblxuICAgIC8qIGlPUyBtb21lbnR1bSBzY3JvbGxpbmcuICovXG4gICAgLXdlYmtpdC1vdmVyZmxvdy1zY3JvbGxpbmc6IHRvdWNoO1xuICB9XG4iLG51bGxdfQ== */</style>

<ul bind:this={el} bind:clientHeight>
  <!-- "Runway", governing the container's overall scroll height -- empty so as
       to avoid thrashing the GPU. -->
  <div
    class="absolute w-px h-px"
    style="transform: translate(0, {scrollHeight}px);"
  />

  {#each stack as { item, top }, idx (idx)}
    <li class="absolute w-full" style="transform: translateY({top}px);">
      <slot name="item" {item} />
    </li>
  {/each}
</ul>
