<script>
  export let variant = 'menu';
</script>

<style>:root{--page-transition-duration:250ms}line{-webkit-transition-property:all;transition-property:all;-webkit-transition-timing-function:cubic-bezier(.4,0,.2,1);transition-timing-function:cubic-bezier(.4,0,.2,1);stroke:currentColor;stroke-width:2;-webkit-transition-duration:.25s;transition-duration:.25s;-webkit-transition-duration:var(--page-transition-duration);transition-duration:var(--page-transition-duration);-webkit-transform-origin:center center;transform-origin:center center}.mdc-navigation-icon--close line:first-child{-webkit-transform:rotate(225deg) translate3d(0,5px,0);transform:rotate(225deg) translate3d(0,5px,0)}.mdc-navigation-icon--close line:nth-child(2){-webkit-transition-timing-function:cubic-bezier(.4,0,1,1);transition-timing-function:cubic-bezier(.4,0,1,1);-webkit-transition-duration:.1s;transition-duration:.1s;-webkit-transition-duration:calc(var(--page-transition-duration)*0.4);transition-duration:calc(var(--page-transition-duration)*0.4);-webkit-transform:rotate(90deg) scaleX(0);transform:rotate(90deg) scaleX(0)}.mdc-navigation-icon--close line:nth-child(3){-webkit-transform:rotate(135deg) translate3d(0,-5px,0);transform:rotate(135deg) translate3d(0,-5px,0)}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL21hdGVyaWFsLWNvbXBvbmVudHMvc3JjL2NvbXBvbmVudHMvTmF2aWdhdGlvbkljb24uc3ZlbHRlIiwiPG5vIHNvdXJjZT4iXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0UsTUFDRSxnQ0FDRixDQUVBLEtDTEYsZ0NBQUEsd0JBQUEsMkRBQUEsbURBQUEsb0JBQUEsQURRSSxjQUFlLENBQ2YsZ0NBQW9ELENBQXBELHdCQUFvRCxDQUFwRCwyREFBb0QsQ0FBcEQsbURBQW9ELENBQ3BELHNDQUErQixDQUEvQiw4QkFDRixDQUdFLDZDQUNFLHFEQUFnRCxDQUFoRCw2Q0FDRixDQUNBLDhDQ2pCSiwwREFBQSxrREFBQSxBRG9CTSwrQkFBZ0UsQ0FBaEUsdUJBQWdFLENBQWhFLHFFQUFnRSxDQUFoRSw2REFBZ0UsQ0FDaEUseUNBQWtDLENBQWxDLGlDQUNGLENBQ0EsOENBQ0Usc0RBQWlELENBQWpELDhDQUNGIiwiZmlsZSI6Ii4uL21hdGVyaWFsLWNvbXBvbmVudHMvc3JjL2NvbXBvbmVudHMvTmF2aWdhdGlvbkljb24uc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG4gIDpyb290IHtcbiAgICAtLXBhZ2UtdHJhbnNpdGlvbi1kdXJhdGlvbjogMjUwbXM7XG4gIH1cblxuICBsaW5lIHtcbiAgICBAYXBwbHkgLnRyYW5zaXRpb24tYWxsIC50cmFuc2l0aW9uLXN0YW5kYXJkIC5zdHJva2UtY3VycmVudDtcblxuICAgIHN0cm9rZS13aWR0aDogMjtcbiAgICB0cmFuc2l0aW9uLWR1cmF0aW9uOiB2YXIoLS1wYWdlLXRyYW5zaXRpb24tZHVyYXRpb24pO1xuICAgIHRyYW5zZm9ybS1vcmlnaW46IGNlbnRlciBjZW50ZXI7XG4gIH1cblxuICAubWRjLW5hdmlnYXRpb24taWNvbi0tY2xvc2UgbGluZSB7XG4gICAgJjpudGgtY2hpbGQoMSkge1xuICAgICAgdHJhbnNmb3JtOiByb3RhdGUoMjI1ZGVnKSB0cmFuc2xhdGUzZCgwLCA1cHgsIDApO1xuICAgIH1cbiAgICAmOm50aC1jaGlsZCgyKSB7XG4gICAgICBAYXBwbHkgLnRyYW5zaXRpb24tYWNjZWxlcmF0aW9uO1xuXG4gICAgICB0cmFuc2l0aW9uLWR1cmF0aW9uOiBjYWxjKHZhcigtLXBhZ2UtdHJhbnNpdGlvbi1kdXJhdGlvbikgKiAwLjQpO1xuICAgICAgdHJhbnNmb3JtOiByb3RhdGUoOTBkZWcpIHNjYWxlWCgwKTtcbiAgICB9XG4gICAgJjpudGgtY2hpbGQoMykge1xuICAgICAgdHJhbnNmb3JtOiByb3RhdGUoMTM1ZGVnKSB0cmFuc2xhdGUzZCgwLCAtNXB4LCAwKTtcbiAgICB9XG4gIH1cbiIsbnVsbF19 */</style>

<svg
  class="mdc-navigation-icon mdc-navigation-icon--{variant}"
  width="24"
  height="24"
  viewBox="0 0 24 24"
>
  <line x1="3" y1="7" x2="21" y2="7" />
  <line x1="3" y1="12" x2="21" y2="12" />
  <line x1="3" y1="17" x2="21" y2="17" />
</svg>
