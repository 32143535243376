<script>
  import {
    mdiAccountOutline,
    mdiAccountMultipleOutline,
    mdiBellOutline,
    mdiLogout,
    mdiSettingsOutline,
    mdiTimetable,
  } from '@mdi/js';
  import { createEventDispatcher, getContext, onMount } from 'svelte';
  import Avatar from '@/components/Avatar';
  import SideSheet from '@/components/SideSheet';
  import { user } from '@/stores/session';
  import Item from './Item';

  const dispatch = createEventDispatcher();
  const events = getContext('bus');

  let unsubscribe = () => {};
  let closed = true;

  const toggle = () => {
    closed ^= true;
  };

  onMount(() => {
    ({ unsubscribe } = events.subscribe('drawer', toggle));
    return unsubscribe;
  });
</script>

<style>header{border-color:var(--divider)}header:before{display:block;padding-top:56.25%;content:""}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL05hdmlnYXRpb25EcmF3ZXIvaW5kZXguc3ZlbHRlIiwiPG5vIHNvdXJjZT4iXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0UsT0FDRSwyQkFTRixDQVBFLGNDSkosY0FBQSxBRFFNLGtCQUFnQyxDQUNoQyxVQUNGIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL05hdmlnYXRpb25EcmF3ZXIvaW5kZXguc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG4gIGhlYWRlciB7XG4gICAgYm9yZGVyLWNvbG9yOiB2YXIoLS1kaXZpZGVyKTtcblxuICAgICY6OmJlZm9yZSB7XG4gICAgICBAYXBwbHkgYmxvY2s7XG5cbiAgICAgIC8qIDE2OjkgcmF0aW8uICovXG4gICAgICBwYWRkaW5nLXRvcDogY2FsYyg5IC8gMTYgKiAxMDAlKTtcbiAgICAgIGNvbnRlbnQ6ICcnO1xuICAgIH1cbiAgfVxuIixudWxsXX0= */</style>

{#if !closed}
  <SideSheet
    modal
    on:close={() => {
      closed = true;
    }}
  >
    <header class="relative mb-1 border-b">
      <div class="absolute inset-0 flex flex-col justify-around p-4 pb-2">
        <Avatar person={$user} />
        <ul>
          <li class="text-xl font-semibold">{$user.name}</li>
          <li class="text-sm text-grey-600">{$user.email}</li>
        </ul>
      </div>
    </header>

    <nav>
      <Item route="planner" icon={mdiTimetable} />
      <Item route="staff" icon={mdiAccountMultipleOutline} />
      <Item route="settings" icon={mdiSettingsOutline} />

      <hr class="hr" />

      <Item route="notifications" icon={mdiBellOutline} />
      <Item route="profile" icon={mdiAccountOutline} />
      <Item
        route="session"
        icon={mdiLogout}
        on:click={() => dispatch('session:revoke')}
      />
    </nav>
  </SideSheet>
{/if}
