<script>
  import { toISODate } from '@absently/date-fns-ext';
  import { tick } from 'svelte';
  import Button from '../Button.svelte';
  import Calendar from './Calendar.svelte';
  import Dialog from '../Dialog.svelte';
  import Menu from '../Menu.svelte';
  import TextField from '../TextField.svelte';

  export let mobile = false;
  export let label = 'select date';
  export let value = null;
  export let format = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'short',
  };

  let closed = true;
  let calendar;

  $: is = mobile ? Dialog : Menu;
  $: selection = value;
  $: input = value ? toISODate(value) : '';

  const open = () => {
    closed = false;
    tick().then(() => calendar.focus());
  };

  const close = () => {
    closed = true;
  };

  const serializer = date =>
    new Date(date).toLocaleDateString('default', format);

  const handleSelect = async ({ detail: { start: date } }) => {
    value = date;
    close();
  };

  const confirm = () => {};
  const dismiss = () => {};
</script>

<style>:global(.mdc-date-picker .mdc-dialog__surface){width:calc(100vw - 2rem)}header{height:12em}header .label{height:3.2em}header .label .text{font-size:1em}header .value{height:7.2em}header .value .text{font-size:3.5em}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL21hdGVyaWFsLWNvbXBvbmVudHMvc3JjL2NvbXBvbmVudHMvcGlja2VyL0RhdGVQaWNrZXIuc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFLCtDQUNFLHdCQUNGLENBRUEsT0FDRSxXQWlCRixDQWZFLGNBQ0UsWUFLRixDQUhFLG9CQUNFLGFBQ0YsQ0FHRixjQUNFLFlBS0YsQ0FIRSxvQkFDRSxlQUNGIiwiZmlsZSI6Ii4uL21hdGVyaWFsLWNvbXBvbmVudHMvc3JjL2NvbXBvbmVudHMvcGlja2VyL0RhdGVQaWNrZXIuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG4gIDpnbG9iYWwoLm1kYy1kYXRlLXBpY2tlciAubWRjLWRpYWxvZ19fc3VyZmFjZSkge1xuICAgIHdpZHRoOiBjYWxjKDEwMHZ3IC0gKDIgKiB0aGVtZSgnc3BhY2luZy40JykpKTtcbiAgfVxuXG4gIGhlYWRlciB7XG4gICAgaGVpZ2h0OiAxMmVtO1xuXG4gICAgJiAubGFiZWwge1xuICAgICAgaGVpZ2h0OiAzLjJlbTtcblxuICAgICAgJiAudGV4dCB7XG4gICAgICAgIGZvbnQtc2l6ZTogMWVtO1xuICAgICAgfVxuICAgIH1cblxuICAgICYgLnZhbHVlIHtcbiAgICAgIGhlaWdodDogNy4yZW07XG5cbiAgICAgICYgLnRleHQge1xuICAgICAgICBmb250LXNpemU6IDMuNWVtO1xuICAgICAgfVxuICAgIH1cbiAgfVxuIl19 */</style>

<svelte:component this={is} bind:closed class="mdc-date-picker">
  <div slot="activator" aria-expanded={!closed} on:focusin={open}>
    <TextField
      active={!closed}
      label="Date"
      value={input}
      readonly
      {serializer}
    />
  </div>
  <div class:text-fluid={mobile}>
    {#if is === Dialog}
      <header class="px-6 text-on-primary bg-primary">
        {#if label}
          <h2 class="label relative uppercase">
            <span class="text absolute bottom-0 font-medium tracking-widest">
              {label}
            </span>
          </h2>
        {/if}
        <div class="value relative">
          <span class="text absolute bottom-0 leading-none">
            {#if value}
              {value.toLocaleDateString('default', {
                month: 'short',
                day: 'numeric',
                weekday: 'short',
              })}
            {:else}Start date{/if}
          </span>
        </div>
      </header>
    {/if}

    <Calendar
      bind:this={calendar}
      {mobile}
      density={mobile ? 'default' : 'compact'}
      {selection}
      on:cell:click={handleSelect}
    />
  </div>
  <footer slot="actions" class="mdc-dialog__actions">
    {#if is === Dialog}
      <Button color="primary" on:click={dismiss}>cancel</Button>
      <Button color="primary" on:click={confirm}>ok</Button>
    {/if}
  </footer>
</svelte:component>
