<script>
  import { fade } from 'svelte/transition';

  export let transition = { in: { duration: 150 }, out: { duration: 75 } };
</script>

<style>div{z-index:-1;opacity:.32}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL21hdGVyaWFsLWNvbXBvbmVudHMvc3JjL2NvbXBvbmVudHMvU2NyaW0uc3ZlbHRlIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFLElBQ0UsVUFBVyxDQUNYLFdBQ0YiLCJmaWxlIjoiLi4vbWF0ZXJpYWwtY29tcG9uZW50cy9zcmMvY29tcG9uZW50cy9TY3JpbS5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbiAgZGl2IHtcbiAgICB6LWluZGV4OiAtMTtcbiAgICBvcGFjaXR5OiAwLjMyO1xuICB9XG4iXX0= */</style>

<div
  in:fade={transition.in || transition}
  out:fade={transition.out || transition}
  class="scrim fixed inset-0 w-full h-full bg-on-surface"
  on:click
/>
