<script>
  import { isAmEnd, isPmStart, startOfAfternoon } from '@absently/date-fns-ext';
  import uid from '@absently/uid';
  import {
    addDays,
    differenceInDays,
    isAfter,
    isBefore,
    isEqual,
    max,
    min,
  } from 'date-fns';
  import get from 'just-safe-get';
  import { dragselect } from './actions';
  import Entry from './Entry';
  import page from '@/stores/page';
  import { absences, interval, staff } from '@/stores/planner';
  import store from '@/stores/services';
  import { customProperties as styles } from '@/styles/variables';
  import { getAbbrDayName } from '@/utils/i18n';

  const cw = parseInt(styles['--wc-col-width'], 10);
  const rh = parseInt(styles['--wc-row-height'], 10);

  export let rows;
  export let cols;

  let drag;

  // [TODO] sveltejs/sapper#741.
  $: absUrl = $page.href;
  $: style = `
    grid-row-end: span ${rows.length};
    grid-column-end: span ${cols.length};
    height: ${rows.length * rh}px;
  `;
  $: if (drag) {
    const { emp, origin, over } = drag;
    const bounds = $absences.reduce(({ start, end }, id) => {
      const absence = store.absences.peek(id);
      if (id === drag.id || absence.absentee !== emp) return { start, end };

      return {
        start:
          !isAfter(absence.start, origin.start) && isAfter(absence.end, start)
            ? absence.end
            : start,
        end:
          !isBefore(absence.end, origin.end) && isBefore(absence.start, end)
            ? absence.start
            : end,
      };
    }, $interval);

    store.absences.add({
      id: drag.id,
      absentee: emp,
      // TODO: support for actual ad-hoc hour-based intervals of absence.
      abstract: true,
      start: min([origin.start, max([bounds.start, over.start])]),
      end: max([origin.end, min([bounds.end, over.end])]),
    });
  }

  const updateSelection = ev => {
    const [, colindex] = ev.detail.gridcell;
    const d = addDays($interval.start, colindex - 1);
    const noon = startOfAfternoon(d);
    const isAm = colindex % 1 < 0.5;
    const over = { start: isAm ? d : noon, end: isAm ? noon : addDays(d, 1) };

    if (!isEqual(over.start, get(drag, 'over.start'))) {
      drag = { ...drag, ...(!drag.over ? { origin: over } : {}), over };
    }
  };

  const handleDragStart = ev => {
    const [rowindex] = ev.detail.gridcell;

    drag = { id: uid('absence'), emp: $staff[rowindex - 1] };
    updateSelection(ev);
  };

  const handleDragOver = ev => updateSelection(ev);

  const handleDragEnd = ev => {
    updateSelection(ev);

    const absence = store.absences.peek(drag.id);
    const days = differenceInDays(absence.end, absence.start);
    // eslint-disable-next-line no-bitwise
    const units = isPmStart(absence) ^ isAmEnd(absence) ? days + 0.5 : days;

    // TODO: emit reference to new absence and present confirmation dialog.
    store.absences.post({ ...absence, units });
    drag = undefined;
  };
</script>

<style>.canvas{width:100%;grid-row-start:2;grid-column-start:2;overflow:visible!important;isolation:isolate}:global(#overlay) text{text-anchor:middle}.overlay{mix-blend-mode:color-dodge}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL1dhbGxjaGFydC9DYW52YXMuc3ZlbHRlIiwiPG5vIHNvdXJjZT4iXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0UsUUNERixXQUFBLEFESUksZ0JBQWlCLENBQ2pCLG1CQUFvQixDQUNwQiwwQkFBNEIsQ0FDNUIsaUJBQ0YsQ0FFQSx1QkFDRSxrQkFNRixDQUVBLFNBQ0UsMEJBQ0YiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvV2FsbGNoYXJ0L0NhbnZhcy5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbiAgLmNhbnZhcyB7XG4gICAgQGFwcGx5IHctZnVsbDtcblxuICAgIGdyaWQtcm93LXN0YXJ0OiAyO1xuICAgIGdyaWQtY29sdW1uLXN0YXJ0OiAyO1xuICAgIG92ZXJmbG93OiB2aXNpYmxlICFpbXBvcnRhbnQ7XG4gICAgaXNvbGF0aW9uOiBpc29sYXRlO1xuICB9XG5cbiAgOmdsb2JhbCgjb3ZlcmxheSkgdGV4dCB7XG4gICAgdGV4dC1hbmNob3I6IG1pZGRsZTtcblxuICAgIC8qXG4gICAgVXNpbmcgZHk9XCIwLjZleFwiIGhhY2sgaW5zdGVhZCBkdWUgdG8gbGFjayBvZiBNUyBicm93c2VyIHN1cHBvcnQuXG4gICAgZG9taW5hbnQtYmFzZWxpbmU6IGNlbnRyYWw7XG4gICAgKi9cbiAgfVxuXG4gIC5vdmVybGF5IHtcbiAgICBtaXgtYmxlbmQtbW9kZTogY29sb3ItZG9kZ2U7XG4gIH1cbiIsbnVsbF19 */</style>

<svg
  {style}
  class="canvas"
  use:dragselect={absences}
  on:dragstart={handleDragStart}
  on:dragover={handleDragOver}
  on:dragend={handleDragEnd}
>
  <defs>
    <pattern
      id="overlay"
      width={1 / (cols.length / 7)}
      height={1 / rows.length}
    >
      {#each Array(7) as _, idx}
        <text
          x={idx * cw + cw / 2}
          y={rh / 2}
          class="text-xs font-thin text-grey-600 fill-current"
          dy="0.6ex"
        >
          {getAbbrDayName(idx).slice(0, 2)}
        </text>
      {/each}
    </pattern>
  </defs>

  {#each $absences as id (id)}
    <Entry absence={store.absences.peek(id)} />
  {/each}

  <rect
    role="presentation"
    class="overlay pointer-events-none"
    width="100%"
    height="100%"
    fill="url({absUrl}#overlay)"
  />
</svg>
