<script>
  import {
    eachDayOfInterval,
    intersection,
    isAmEnd,
    isPmStart,
  } from '@absently/date-fns-ext';
  import { differenceInDays, isAfter, isEqual } from 'date-fns';
  import RoundedPolygon from '@/components/svg/RoundedPolygon';
  import { interval, staff } from '@/stores/planner';
  import { customProperties as styles } from '@/styles/variables';
  import { rotate } from '@/utils/array';

  const cw = parseInt(styles['--wc-col-width'], 10);
  const rh = parseInt(styles['--wc-row-height'], 10);
  const h = cw;
  const my = (rh - h) / 2;
  const ext = parseInt(styles['--wc-arrow-radius'], 10);

  export let absence;

  $: i = intersection(absence, $interval);
  $: exts = {
    l: !isEqual(absence.start, i.start),
    r: !isEqual(absence.end, i.end),
  };
  $: rowindex = $staff.indexOf(absence.absentee);
  $: colindex = Math.max(differenceInDays(absence.start, $interval.start), 0);
  $: colspan = eachDayOfInterval(i).length;
  $: top = rowindex * rh + my;
  $: left = colindex * cw;
  $: w = colspan * cw;
  // Rotate array of points to correct order of open path for intervals that
  // extend beyond end of wallchart bounds.
  //
  // 1.__.2                 3.__.4
  //     |   rotate(2) #=>   |
  // 4.__.3                 2.__.1
  //
  //     .1                    .3
  //     |   rotate(1) #=>    /
  //  3._.2                 2._.1
  $: points = rotate(
    [
      ...new Set(
        // Clockwise from top-left.
        [
          [isPmStart(i) ? cw : 0, 0],
          [w, 0],
          [isAmEnd(i) ? w - cw : w, h],
          [0, h],
        ].map(JSON.stringify)
      ),
    ].map(JSON.parse),
    // eslint-disable-next-line no-nested-ternary
    exts.r ? (isPmStart(i) && colspan === 1 ? 1 : 2) : 0
  );
  $: open = exts.l || exts.r;
  $: omit = open
    ? [...(exts.l && exts.r ? points : [points[0], points[points.length - 1]])]
    : [];
  $: transform = `translate(${left}, ${top})`;
  // [TODO] use `$time.tick` or equivalent.
  $: isLapsed = !isAfter(absence.end, Date.now());
  $: style = exts.l && exts.r ? `stroke-dasharray: ${w}, ${h}` : null;

  const handleClick = () => {};
</script>

<style>g[data-state=new]{fill:#b0bec5;fill:var(--absence-new)}g[data-state=new] :global(path){stroke:#96a2a7}g[data-state=new].is-lapsed{fill:#d8dfe2}g[data-state=new].is-lapsed :global(path){stroke:#ccd5d9}g[data-state=requested]{fill:#ffc107;fill:var(--absence-requested)}g[data-state=requested] :global(path){stroke:#d9a406}g[data-state=requested].is-lapsed{fill:#ffe083}g[data-state=requested].is-lapsed :global(path){stroke:#ffd75e}g[data-state=confirmed]{fill:#03a9f4;fill:var(--absence-confirmed)}g[data-state=confirmed] :global(path){stroke:#0390cf}g[data-state=confirmed].is-lapsed{fill:#81d4fa}g[data-state=confirmed].is-lapsed :global(path){stroke:#5bc7f8}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL1dhbGxjaGFydC9FbnRyeS5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUksa0JBQ0UsWUFBNkIsQ0FBN0IsdUJBYUYsQ0FYRSxnQ0FDRSxjQUNGLENBRUEsNEJBQ0UsWUFLRixDQUhFLDBDQUNFLGNBQ0YsQ0FaSix3QkFDRSxZQUE2QixDQUE3Qiw2QkFhRixDQVhFLHNDQUNFLGNBQ0YsQ0FFQSxrQ0FDRSxZQUtGLENBSEUsZ0RBQ0UsY0FDRixDQVpKLHdCQUNFLFlBQTZCLENBQTdCLDZCQWFGLENBWEUsc0NBQ0UsY0FDRixDQUVBLGtDQUNFLFlBS0YsQ0FIRSxnREFDRSxjQUNGIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL1dhbGxjaGFydC9FbnRyeS5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbiAgQGVhY2ggJHN0YXRlIGluIG5ldywgcmVxdWVzdGVkLCBjb25maXJtZWQge1xuICAgIGdbZGF0YS1zdGF0ZT0nJChzdGF0ZSknXSB7XG4gICAgICBmaWxsOiB2YXIoLS1hYnNlbmNlLSQoc3RhdGUpKTtcblxuICAgICAgJiA6Z2xvYmFsKHBhdGgpIHtcbiAgICAgICAgc3Ryb2tlOiBjb2xvci1tb2QodmFyKC0tYWJzZW5jZS0kKHN0YXRlKSkgc2hhZGUoMTUlKSk7XG4gICAgICB9XG5cbiAgICAgICYuaXMtbGFwc2VkIHtcbiAgICAgICAgZmlsbDogY29sb3ItbW9kKHZhcigtLWFic2VuY2UtJChzdGF0ZSkpIHRpbnQoNTAlKSk7XG5cbiAgICAgICAgJiA6Z2xvYmFsKHBhdGgpIHtcbiAgICAgICAgICBzdHJva2U6IGNvbG9yLW1vZCh2YXIoLS1hYnNlbmNlLSQoc3RhdGUpKSB0aW50KDM1JSkpO1xuICAgICAgICB9XG4gICAgICB9XG4gICAgfVxuICB9XG4iXX0= */</style>

<g
  id={absence.id}
  class="hover:cursor-pointer"
  class:is-lapsed={isLapsed}
  aria-rowindex={rowindex + 1}
  aria-colindex={colindex + 1}
  {transform}
  data-state={absence.state || 'new'}
  on:click={handleClick}
  on:mousedown|stopPropagation
>
  {#if exts.l}
    <polygon points={`0,${h} ${-ext},${h / 2} 0,0`} />
  {/if}

  <RoundedPolygon {points} {open} rad={2} {omit} {style} />

  {#if exts.r}
    <polygon points={`${w},0 ${w + ext},${h / 2} ${w},${h}`} />
  {/if}
</g>
