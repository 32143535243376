<script>
  import { addDays, startOfWeek } from 'date-fns';
  import { getContext } from 'svelte';

  const { weekStartsOn } = getContext('l10n');

  $: week = [...Array(7 - 1)].reduce(
    acc => [...acc, addDays(acc[acc.length - 1], 1)],
    [startOfWeek(Date.now(), { weekStartsOn })]
  );
</script>

<style>[role=columnheader]{height:var(--cal-cell-height)}abbr[title]{text-decoration:none}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi4uL21hdGVyaWFsLWNvbXBvbmVudHMvc3JjL2NvbXBvbmVudHMvcGlja2VyL0dyaWRIZWFkZXIuc3ZlbHRlIiwiPG5vIHNvdXJjZT4iXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0Usb0JBQ0UsNkJBQ0YsQ0FFQSxZQ0xGLG9CRE9FIiwiZmlsZSI6Ii4uL21hdGVyaWFsLWNvbXBvbmVudHMvc3JjL2NvbXBvbmVudHMvcGlja2VyL0dyaWRIZWFkZXIuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG4gIFtyb2xlPSdjb2x1bW5oZWFkZXInXSB7XG4gICAgaGVpZ2h0OiB2YXIoLS1jYWwtY2VsbC1oZWlnaHQpO1xuICB9XG5cbiAgYWJiclt0aXRsZV0ge1xuICAgIEBhcHBseSBuby11bmRlcmxpbmU7XG4gIH1cbiIsbnVsbF19 */</style>

<header role="row" class="flex">
  {#each week as day}
    <div
      role="columnheader"
      class="flex items-center justify-center leading-none"
    >
      <abbr title={day.toLocaleDateString('default', { weekday: 'long' })}>
        {day.toLocaleDateString('default', { weekday: 'short' })[0]}
      </abbr>
    </div>
  {/each}
</header>
