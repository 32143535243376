<script>
  import { Backdrop } from '@absently/mdc';
  import {
    addMonths,
    addWeeks,
    startOfMonth,
    startOfToday,
    subMonths,
    subWeeks,
  } from 'date-fns';
  import { getContext, onMount } from 'svelte';
  import page from '@/stores/page';
  import store from '@/stores/services';
  import { customProperties as styles } from '@/styles/variables';
  import { localize } from '@/utils/i18n';

  const rh = parseInt(styles['--mdc-calendar-row-height--comfortable'], 10);
  const pb = 4;
  // Backdrop's back layer conceals a calendar either revealing rows equivalent
  // to a single week or a whole month (inc. header row).
  const breakpoints = [rh * 2 + pb, rh * 7 + pb];

  export let team;
  export let date;

  const events = getContext('bus');

  let concealed = true;

  $: $page.heading = localize(date, { year: 'numeric', month: 'short' });
  $: {
    // Prefetch absences a month either side of that currently visible.
    const s = startOfMonth(date);

    store.absences.fetch(team, {
      start: subMonths(s, 1),
      end: addMonths(s, 2),
    });
  }

  onMount(() => {
    // eslint-disable-next-line no-use-before-define
    const { unsubscribe } = events.subscribe('planner:page', handlePagination);

    return unsubscribe;
  });

  const handlePagination = direction => {
    switch (direction) {
      case 'prev':
        date = concealed ? subWeeks(date, 1) : subMonths(date, 1);
        break;
      case 'next':
        date = concealed ? addWeeks(date, 1) : addMonths(date, 1);
        break;
      default:
        date = startOfToday();
    }
  };
</script>

<style>:global(.mdc-backdrop .mdc-calendar-grid>header){display:none}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL0FnZW5kYS5zdmVsdGUiLCI8bm8gc291cmNlPiJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRSxpRENERixZREdFIiwiZmlsZSI6InNyYy9jb21wb25lbnRzL0FnZW5kYS5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbiAgOmdsb2JhbCgubWRjLWJhY2tkcm9wIC5tZGMtY2FsZW5kYXItZ3JpZCA+IGhlYWRlcikge1xuICAgIEBhcHBseSBoaWRkZW47XG4gIH1cbiIsbnVsbF19 */</style>

<Backdrop bind:concealed {breakpoints}>
  <slot name="calnav" {concealed} />

  <span slot="subheader">
    {localize(date, { month: 'long', day: 'numeric', weekday: 'long' })}
  </span>
</Backdrop>
