<script>
  export let alt;
  export let signup = false;
</script>

<style>.slack-button--signup{color:#fff;background-color:#4a154b;border-style:none}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9jb21wb25lbnRzL1NsYWNrQnV0dG9uLnN2ZWx0ZSIsIjxubyBzb3VyY2U+Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFLHNCQ0RGLFdBQUEseUJBQUEsaUJER0UiLCJmaWxlIjoic3JjL2NvbXBvbmVudHMvU2xhY2tCdXR0b24uc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG4gIC5zbGFjay1idXR0b24tLXNpZ251cCB7XG4gICAgQGFwcGx5IHRleHQtd2hpdGUgYmctc2xhY2stYXViZXJnaW5lIGJvcmRlci1ub25lO1xuICB9XG4iLG51bGxdfQ== */</style>

<a href="/auth/slack" target="_self" class="inline-block" on:click>
  <div
    class="slack-button flex items-center h-10 bg-white border border-grey-400
    rounded pointer-events-none"
    class:slack-button--signup={signup}
  >
    <img src="static/slack.svg" {alt} class="w-12 h-12 -mx-1" />
    <span class="mr-3 text-base font-medium">
      <slot />
    </span>
  </div>
</a>
